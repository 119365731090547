import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Hero } from "../components/Hero";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Owner } from "../interfaces/Owner";
import { useEffect, useState } from "react";
import { TestData } from "../test/data/TestData";

export function HomePage() {
    const [owner, setOwner] = useState<Owner>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Owner);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>
            {owner && <Header owner={owner} />}

            {owner && <Hero />}

            {owner &&
                <Grid container style={{ minHeight: '20vh' }} >
                    <Grid item md={4} xs={12} style={{ padding: '20px' }} >
                        <div className="SectionTitle">Owner Payments</div>
                        <div style={{ fontSize: '1.1em', padding: '15px' }} >You have a payment of <b>${owner?.BalanceDue.toFixed(2)}</b> due.</div>
                        <div style={{ fontSize: '1.1em', padding: '15px', width: '100%', textAlign: 'center' }} >
                            <Link to="/owner-payments"><button style={{ padding: '10px 15px', backgroundColor: '#22a845', border: '0px solid', borderRadius: '5px', color: 'white' }} >Make a Payment</button></Link>
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12} style={{ padding: '20px' }} >
                        <div className="SectionTitle">Owner Services</div>
                            <ul style={{ lineHeight: '175%', marginLeft: '40px', marginTop: '10px' }}>
                                <li><Link to="/service-request" className="blue">Villa Service Request</Link></li>
                                <li><Link to="/" className="blue">Request Additional Vacation Rental</Link></li>
                                <li><Link to="/" className="blue">Request Rental Listing Agreement</Link></li>
                                <li><Link to="/" className="blue">Contact Me About Purchasing</Link></li>
                            </ul>
                    </Grid>
                </Grid>
            }

            {owner && <Footer />}
        </div>
    )
}