import { Owner } from "./interfaces/Owner";
import { TestData } from "./test/data/TestData";
// import { ENV } from "./Global";
import { getCookie, setCookie } from 'typescript-cookie'
import { Md5 } from "md5-typescript";
import OwnerLoginCredentials from "./interfaces/OwnerLoginCredentials";

export async function loginWithPassword(ownerLoginCredentials: OwnerLoginCredentials, successCallback: ((owner: Owner) => void), errorCallback: ((error: any) => any)): Promise<Owner | any> {
  console.log(ownerLoginCredentials);
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer IxEe8MDzioXMO2ZzDC1q");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(ownerLoginCredentials)
  };

  fetch(`https://rdpapi.azurewebsites.net/api/owner/login`, requestOptions)
    .then(response => {
      if (!response.ok) {
        errorCallback(response);
      } else {
        return response.json();
      }
    })
    .then((result) => {
      console.log(result);
      const owner: Owner = result;
      if (owner) {
        setCookie('owner', JSON.stringify(owner));
      }
      successCallback(owner);  
    })
    .catch(error => errorCallback(error));
}

export async function authOwnerWithToken(ownerNumber: string, token: string, successCallback: ((owner: Owner) => void), errorCallback: ((error: any) => any)): Promise<Owner | any> {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer IxEe8MDzioXMO2ZzDC1q");

  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`https://rdpapi.azurewebsites.net/api/owner/2/${ownerNumber}/${token}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        errorCallback(response);
      } else {
        return response.json();
      }
    })
    .then((result) => {
      const owner: Owner = result;
      setCookie('ownerNumber', owner.Number);
      setCookie('token', owner.IRMPassword);
      successCallback(result);
    })
    .catch(error => errorCallback(error));
}

