import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from '../images/logo.png';
import { Owner } from "../interfaces/Owner";

export function Header(props: any) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container style={{ width: '100%', minHeight: '80px', backgroundColor: '#053e8f', color: 'white' }}>
          <Grid item xs={6}>
            <img src={logo} style={{ height: '80px', paddingTop: '10px' }} />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right', padding: '25px' }}>
            <div>Welcome back, {props.owner.Name.substring(props.owner.Name.indexOf('/') + 1)} </div>
            <div>
              <div style={{ display: 'inline-block', paddingLeft: '20px', paddingTop: '6px', fontSize: '0.75em', cursor: 'pointer' }}>My Profile</div>
              <div style={{ display: 'inline-block', paddingLeft: '20px', paddingTop: '6px', fontSize: '0.75em', cursor: 'pointer' }}>Support</div>
              <Link to={"/login"}><div style={{ display: 'inline-block', paddingLeft: '20px', paddingTop: '6px', fontSize: '0.75em', cursor: 'pointer' }}>Logout</div></Link>
            </div>
          </Grid>
        </Grid>
  
        <Grid container style={{ width: '100%', minHeight: '30px', backgroundColor: '#031d42', color: 'white' }}>
          <Link to="/"><div className="main-nav-link">Home</div></Link>
          <Link to="/owner-payments"><div className="main-nav-link">Owner Payments</div></Link>
          <Link to="/upcoming-vacations"><div className="main-nav-link">Upcoming Vacations</div></Link>
          <Link to="/owner-services"><div className="main-nav-link">Owner Services</div></Link>
          <div className="main-nav-link">Update Contact Preferences</div>
        </Grid>
      </Grid>
    )
  }
  