import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { ServiceRequest } from './pages/ServiceRequest';
import OwnerPayments from './pages/OwnerPayments';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/service-request" element={<ServiceRequest />} />
        <Route path="/owner-payments" element={<OwnerPayments />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
