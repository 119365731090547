import { Grid } from "@material-ui/core";
import logo from '../images/logo.png';
import hero from '../images/hero.png';
import "../css/main.css";
import * as data from '../Data'
import { removeCookie } from 'typescript-cookie'
import { Owner } from "../interfaces/Owner";
import { Md5 } from "md5-typescript";
import OwnerLoginCredentials from "../interfaces/OwnerLoginCredentials";
import { MouseEvent } from "react";

const forgotPassword = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    console.log("forgot password");
}

const loginWithPassword = async () => {
    console.log("Login attempt");
    const loginButton = document.getElementById('login-button') as HTMLButtonElement;
    loginButton.disabled = true;
    loginButton.innerText = 'Signing in, please wait...';
    removeErrorMessage();

    const ownerNumber = (document.getElementById('ownerNumber') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;
    const irmPassword = Md5.init(ownerNumber + password).substring(0, 12);

    const ownerLoginCredentials: OwnerLoginCredentials = {
        ownerNumber: ownerNumber,
        irmPassword: irmPassword
    }

    const ownerAuth = await data.loginWithPassword(
        ownerLoginCredentials,
        (owner: Owner) => {
            if (owner) {
                window.location.href = "/";
            } else {
                addErrorMessage('The owner number and password are incorrect. Please try again.');
                loginButton.disabled = false;
                loginButton.innerText = 'Login';    
            }
        },
        (error) => {
            console.log(error);
            addErrorMessage('There was a problem signing in. Please try again or contact owner services for technical support.');
            loginButton.disabled = false;
            loginButton.innerText = 'Login';
        }
    )
}

const removeErrorMessage = () => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.add("hidden");
    errorMessage.innerHTML = '';
}

const addErrorMessage = (message: string) => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.remove("hidden");
    errorMessage.innerText = message;
}

export function LoginPage() {

    removeCookie('owner');

    return (
        <div>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid container style={{ width: '100%', minHeight: '80px', backgroundColor: '#053e8f', color: 'white' }}>
                    <Grid item xs={12}>
                        <img src={logo} style={{ height: '80px', paddingTop: '10px' }} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container>
                <div style={{
                    backgroundImage: `url(${hero})`,
                    minHeight: '100vh',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: '100%',
                }}>
                </div>

                <div className="modal">
                    <div className="modal-content">
                        <div className="login-title">Owner Portal Login</div>
                        <div className="login-form">
                            <table cellSpacing="8">
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'right', position: 'relative', top: '5px' }}>Owner Number:</td>
                                        <td><input id="ownerNumber" className="login-input" type="text" size={22} defaultValue="9308"></input></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right', position: 'relative', top: '5px' }}>Password:</td>
                                        <td><input id="password" className="login-input" type="password" size={22} defaultValue="1234"></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button id="login-button" onClick={loginWithPassword} style={{ cursor: 'pointer', margin: '10px 0px 30px 0px', padding: '10px 30px', backgroundColor: '#22a845', border: '0px solid', borderRadius: '5px', color: 'white' }} >Login</button>
                            <div><a onClick={(e) => forgotPassword(e)} style={{ cursor: 'pointer', color: 'blue' }} href=''>Forgot your password?</a></div>
                        </div>
                        <div id="error-message" className="login-error hidden"></div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}