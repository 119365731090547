import { Grid } from "@material-ui/core";
import hero from '../images/hero.png';

export function Hero() {
    return (
        <Grid container>
            <div style={{
                backgroundImage: `url(${hero})`,
                minHeight: '40vh',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative',
                width: '100%',
            }}>
            </div>
        </Grid>
    )
}
