import React from "react";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import "../css/main.css";
import "../css/payment.css";
import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { HeroSecondary } from "../components/HeroSecondary";
import ccicons from '../images/credit-card-icons.png';
import routingnumberimage from '../images/routingnumber.jpg';
import vgbanner from '../images/vacation-guard-banner.jpg';

class OwnerPayments extends React.Component {
    constructor() {
        super();

        this.state = {
            owner: JSON.parse(getCookie('owner')),
            paymentMethod: '',
            totalAmount: 0
        }

        console.log(this.state.owner);

        if (this.state.owner === undefined) {
            window.location.href = '/login';
            return;
        }
    }

    componentDidMount() {
        let payButton = document.getElementById('paybutton');
        let firstName = document.getElementById('cardholder-first-name');
        let lastName = document.getElementById('cardholder-last-name');
        let email = document.getElementById('email');
        let phone = document.getElementById('phone');
        let weekNumber = document.getElementById('week-number');
        let unitNumber = document.getElementById('unit-number');
        let ownerId = document.getElementById('owner-id');
        let paymentAmount = document.getElementById('payment-amount');
        let address = document.getElementById('cardholder-address');
        let city = document.getElementById('cardholder-city');
        let state = document.getElementById('cardholder-state');
        let zipcode = document.getElementById('cardholder-zipcode');
        let month = document.getElementById('month');
        let year = document.getElementById('year');
        let payByCreditCard = document.getElementById('paybycreditcard');
        let payByBankAccount = document.getElementById('paybybankaccount');
        let creditCardContainer = document.getElementById('creditcard-container');
        let bankAccountContainer = document.getElementById('bankaccount-container');
        let bankRoutingNumber = document.getElementById('bank-routing-number');
        let bankAccountNumber = document.getElementById('bank-account-number');
        let paymentSection = document.getElementById('payment-section');
        let paymentResponse = document.getElementById('payment-response');
        let paymentReference = document.getElementById('payment-reference');
        let processingFeeMessage = document.getElementById('processing-fee-message');
        let validCreditCard = false;
        let validCvv = false;
        let staxJs;

        let staxScript = document.createElement('script');
        staxScript.src = 'https://staxjs.staxpayments.com/stax.js?nocache=2';
        staxScript.addEventListener('load', function () {
            console.log("loaded");
            console.log(window.StaxJs);


            staxJs = new window.StaxJs('Reveon-fa39024a300d', {
                number: {
                    id: 'card-number',
                    placeholder: '0000 0000 0000 0000',
                    style: 'width: 85%; font-size:14px; padding: 14px 10px 10px 18px; border: 1px solid #aaa; border-radius: 10px; color: #444;'
                },
                cvv: {
                    id: 'card-cvv',
                    placeholder: '000',
                    style: 'width: 75%; font-size:14px; padding: 14px 10px 10px 18px; border: 1px solid #aaa; border-radius: 10px; color: #444;'
                }
            });

            staxJs.showCardForm().then(handler => {
                //appInsights.trackEvent({name:"PaymentFormLoaded"});
                handler.setTestPan('4111111111111111');
                handler.setTestCvv('123');
                firstName.value = 'John';
                lastName.value = 'Smith';
                weekNumber.value = "11";
                unitNumber.value = "A34";
                ownerId.value = "1200";
                email.value = 'john@test.com';
                address.value = '123 Main Street';
                city.value = 'Seattle';
                state.value = 'WA';
                zipcode.value = '12345';
                phone.value = '206-555-1212';
                month.value = '03';
                year.value = '2022';
                bankRoutingNumber.value = '021000021';
                bankAccountNumber.value = '9876543210';
            }).catch(err => {
                console.log(err);
                //appInsights.trackEvent({name:"ErrorLoadingForm:" + err});
            });

            staxJs.on('card_form_complete', (message) => {
                validCreditCard = message.validNumber;
                validCvv = message.validCvv;
                console.log(validCreditCard);
                console.log(validCvv);
            });

            staxJs.on('card_form_incomplete', (message) => {
                validCreditCard = message.validNumber;
                validCvv = message.validCvv;
                console.log(validCreditCard);
                console.log(validCvv);
            });

        })

        payButton.onclick = (e) => {
            console.log('clicked');
            e.preventDefault();
            payButton.disabled = true;
            //appInsights.trackEvent({ name: "PayButtonClicked" });

            if (!validCreditCard || !validCvv) {
                alert("Credit card number or CVV are incorrect. Please check your credit card information and try again.");
                return;
            }

            if (this.validateFields() == false) {
                payButton.disabled = false;
                return;
            } else {
                payButton.disabled = true;
            };

            var resortName = "The Village at Palmetto Dunes";

            payButton.innerHTML = "Processing payment...";

            var form = document.querySelector('form');
            var extraDetails = {
                total: this.state.totalAmount,
                firstname: firstName.value,
                lastname: lastName.value,
                person_name: firstName.value + " " + lastName.value,
                company: '',
                email: email.value,
                month: month.value,
                year: year.value,
                phone: phone.value,
                address_1: address.value,
                address_2: '',
                address_city: city.value,
                address_state: state.value,
                address_zip: zipcode.value,
                url: "https://omni.fattmerchant.com/#/bill/",
                method: this.state.paymentMethod,
                bank_type: 'checking',
                bank_holder_type: 'personal',
                bank_routing: bankRoutingNumber.value,
                bank_account: bankAccountNumber.value,
                validate: false,
                meta: {
                    memo: resortName + ' - Owner: ' + ownerId.value + ', Unit: ' + unitNumber.value + ', Week: ' + weekNumber.value,// optional - will show up in emailed receipts
                }
            };

            // call pay api
            staxJs.pay(extraDetails).then((result) => {
                //appInsights.trackEvent({ name: "PaymentSuccess" });
                console.log(result);
                paymentSection.innerHTML = '';
                if (result.id) {
                    paymentResponse.innerHTML = 'Thank you for your payment of $' + result.total_paid.toFixed(2) + '. Your payment confirmation number is: '
                    var paymentId = result.id;
                    paymentReference.innerHTML = paymentId.substring(0, paymentId.indexOf('-'));
                }
            })
                .catch(err => {
                    //appInsights.trackEvent({ name: "PaymentError" });
                    //appInsights.trackEvent({ name: "Error:" + err + " ErrorForOwner:" + firstName.value + " " + lastName.value + " : " + email.value + " / " + phone.value });
                    console.log(err);
                    if (err.payment_attempt_message) {
                        paymentResponse.innerHTML = err.payment_attempt_message;
                    } else if (typeof err.bank_account !== 'undefined' && err.bank_account.length > 0) {
                        paymentResponse.innerHTML = "<p>There was a problem with your bank routing number or bank account number. Please try again or contact us at 843-842-6556 x11114 for further assistance.</p>";
                        this.updateAmount();
                        console.log(err);
                    } else {
                        paymentResponse.innerHTML = "<p>There was an issue processing your payment. Please contact us at 843-842-6556 x11114 for further assistance.</p>";
                        console.log(err);
                    }
                });
        }


        payByCreditCard.onclick = (e) => {
            e.preventDefault();
            //appInsights.trackEvent({name:"PayByCreditCardClicked"});
            creditCardContainer.style.display = 'block';
            bankAccountContainer.style.display = 'none';
            this.setState({ paymentMethod: 'card' });
            this.updateAmount();
        }

        payByBankAccount.onclick = (e) => {
            e.preventDefault();
            //appInsights.trackEvent({name:"PayByBankAccountClicked"});
            creditCardContainer.style.display = 'none';
            bankAccountContainer.style.display = 'block';
            this.setState({ paymentMethod: 'bank' });
            this.updateAmount();
        }

        document.head.appendChild(staxScript);
    }


    updateAmount = () => {
        console.log('update amount');
        let paymentAmount = document.getElementById('payment-amount');
        let processingFeeMessage = document.getElementById('processing-fee-message');
        var resortOption = "The Village at Palmetto Dunes";

        paymentAmount.value = paymentAmount.value.replace('$', '');
        paymentAmount.value = paymentAmount.value.replace(',', '');

        let totalAmountCalc = paymentAmount.value;

        var processingFeeContent = "The total below includes a credit card convenience fee of 4%."

        if (this.state.paymentMethod == "card") {
            switch (resortOption) {
                case "Spinnaker":
                    break;
                case "Southwind II":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "The Village at Palmetto Dunes":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Ocean Cove Club":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Sand Shares":
                    break;
                case "Ocean Palms":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Ketch Court II":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Night Heron":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Plantation Club":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Port Villas":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Twin Oaks":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                case "Racquet Club":
                    totalAmountCalc = Math.round((paymentAmount.value * 1.04) * 100) / 100;
                    processingFeeMessage.innerHTML = processingFeeContent;
                    break;
                default:
                    break;
            }
        } else {
            totalAmountCalc = paymentAmount.value * 1;
            processingFeeMessage.innerHTML = "";
        }

        this.setState({ totalAmount: totalAmountCalc * 1 }, function () {
            document.getElementById('displayPaymentAmount').innerHTML = this.state.totalAmount.toFixed(2);
        });

    }

    validateFields = () => {
        if (this.state.paymentMethod == "") {
            alert("Please select a payment method.");
            return false;
        }

        if (document.getElementById("week-number").value == "") {
            alert("Please enter a week number.");
            return false;
        } else if (document.getElementById("unit-number").value == "") {
            alert("Please enter a unit number.");
            return false;
        } else if (document.getElementById("owner-id").value == "") {
            alert("Please enter an owner ID.");
            return false;
        } else if (document.getElementById("cardholder-first-name").value == "") {
            alert("Please enter a name.");
            return false;
        } else if (document.getElementById("email").value == "") {
            alert("Please enter an email address.");
            return false;
        } else if (document.getElementById("payment-amount").value == "") {
            alert("Please enter a payment amount");
            return false;
        }
    }

    render() {
        return (
            <div>
                {this.state.owner &&
                    <div>
                        <Header owner={this.state.owner} />
                        <HeroSecondary />

                        <Grid container>
                            <Grid item xs={1}>&nbsp;</Grid>
                            <Grid item xs={10} style={{ padding: '40px 0px' }}>
                                <div>
                                    <div className="SectionTitle">Owner Payments</div>
                                    <div style={{ marginTop: '20px' }}>
                                        <p style={{ width: '100%', textAlign: 'center' }}><img alt="" src={ccicons} style={{ width: '300px', height: '55px' }} /></p>
                                        <p style={{ marginTop: '10px' }}><b>Association dues</b> must be paid in advance of using or banking your unit. In addition, your exchange company requires that the following year's dues are also paid before the week can be banked for that year. Simply pay this year's rate and any difference will be included on your next year's statement. Your resort accepts Master Card, Visa, Discover (debit or credit) and ACH bank account payments for your convenience.</p>
                                        <p style={{ marginTop: '10px' }}><b>Protect your vacation</b>. Add peace of mind by protecting your Maintenance Fees and travel costs with the VacationGuard® Travel Club Plan. With VacationGuard® you get automatic coverage for traveling companions, trip delay, medical expense, baggage, and more. Just $99 per weekly reservation. Enroll by clicking the banner below.</p>
                                        <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                            <b><a target="_blank" href="https://www.hiltonheadusa.com/sites/default/files/files/VG-MaintenanceFeeProtection-Flyer-111820.pdf" style={{ color: '#0000CC' }}>View VacationGuard® plan flyer here</a></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b><a target="_blank" href="http://vacationguard.info/FAQ/CLUB/ " style={{ color: '#0000CC' }}>Click here for VacationGuard® FAQ’s</a></b>
                                        </div>
                                        <p style={{ marginTop: '20px' }}><b>To pay maintenance fees, please provide the following information:</b></p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>&nbsp;</Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={1}>&nbsp;</Grid>
                            <Grid item xs={5} style={{ padding: '0px 10px' }}>
                                <form onSubmit={() => { return false; }}>
                                    <div style={{ marginTop: '-28px' }} className="section-label">Owner Information</div>

                                    <div className="grid-container-name">
                                        <div>
                                            <div className="field-label">First Name</div>
                                            <input id="cardholder-first-name" className="field-input" placeholder="First Name" />
                                        </div>

                                        <div>
                                            <div className="field-label">Last Name</div>
                                            <input id="cardholder-last-name" className="field-input" placeholder="Last Name" />
                                        </div>
                                    </div>

                                    <div className="grid-container-2-1">
                                        <div>
                                            <div className="field-label">Email</div>
                                            <input id="email" className="field-input" placeholder="Email" />
                                        </div>

                                        <div>
                                            <div className="field-label">Phone</div>
                                            <input id="phone" className="field-input" placeholder="000-000-0000" />
                                        </div>
                                    </div>

                                    <div className="grid-container-2-1">
                                        <div>
                                            <div className="field-label">Week Number</div>
                                            <input id="week-number" className="field-input" placeholder="e.g., 12,13,14,15" />
                                        </div>

                                        <div>
                                            <div className="field-label">Unit Number</div>
                                            <input id="unit-number" className="field-input" placeholder="Unit Number" />
                                        </div>
                                    </div>

                                    <div className="grid-container-2-1">
                                        <div>
                                            <div className="field-label">Owner ID</div>
                                            <input id="owner-id" className="field-input" placeholder="Owner ID" />
                                        </div>

                                        <div>
                                            <div className="field-label">Payment Amount</div>
                                            <input id="payment-amount" onBlur={this.updateAmount} className="field-input" placeholder="0.00" />
                                        </div>
                                    </div>

                                    <div className="section-label">Billing Address</div>

                                    <div className="grid-container-1">
                                        <div>
                                            <div className="field-label">Address</div>
                                            <input id="cardholder-address" className="field-input" placeholder="Address" />
                                        </div>
                                    </div>

                                    <div className="grid-container-city">
                                        <div>
                                            <div className="field-label">City</div>
                                            <input id="cardholder-city" className="field-input" placeholder="City" />
                                        </div>

                                        <div>
                                            <div className="field-label">State</div>
                                            <input id="cardholder-state" className="field-input" placeholder="State" />
                                        </div>

                                        <div>
                                            <div className="field-label">Zip Code</div>
                                            <input id="cardholder-zipcode" className="field-input" placeholder="Zip Code" />
                                        </div>
                                    </div>

                                    <div className="section-label">Payment Information</div>
                                    <div id="payment-section">
                                        <div className="payment-type-container">
                                            <button className="payment-type-button" id="paybycreditcard">Pay By Credit Card</button>
                                            <button className="payment-type-button" id="paybybankaccount">Pay By Electronic Check</button>
                                        </div>
                                        <div id="creditcard-container" style={{ display: 'none' }}>
                                            <div className="grid-container-label">
                                                <div className="field-label-drop">Card Number</div>
                                                <div className="field-label-drop">CVV</div>
                                            </div>

                                            <div className="grid-container-card">
                                                <div id="card-number" style={{ maxHeight: '50px' }}></div>
                                                <div id="card-cvv" style={{ maxHeight: '50px' }}></div>
                                            </div>

                                            <div className="grid-container-1-1">
                                                <div>
                                                    <div className="field-label">Exp. Month</div>
                                                    <select id="month" className="field-input">
                                                        <option>Select Month</option>
                                                        <option value="01">01 - January</option>
                                                        <option value="02">02 - February</option>
                                                        <option value="03">03 - March</option>
                                                        <option value="04">04 - April</option>
                                                        <option value="05">05 - May</option>
                                                        <option value="06">06 - June</option>
                                                        <option value="07">07 - July</option>
                                                        <option value="08">08 - August</option>
                                                        <option value="09">09 - September</option>
                                                        <option value="10">10 - October</option>
                                                        <option value="11">11 - November</option>
                                                        <option value="12">12 - December</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <div className="field-label">Exp. Year</div>
                                                    <select id="year" className="field-input">
                                                        <option>Select Year</option>
                                                        <option value="2021">2021</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2023">2023</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2025">2025</option>
                                                        <option value="2026">2026</option>
                                                        <option value="2027">2027</option>
                                                        <option value="2028">2028</option>
                                                        <option value="2029">2029</option>
                                                        <option value="2030">2030</option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div>

                                        <div id="bankaccount-container" style={{ display: 'none' }}>
                                            <div style={{ width: '100%', textAlign: 'center' }}><img src={routingnumberimage} /></div>
                                            <div className="grid-container-1">
                                                <div>
                                                    <div className="field-label">Bank Routing Number</div>
                                                    <input id="bank-routing-number" className="field-input" placeholder="Routing Number" />
                                                </div>
                                            </div>

                                            <div className="grid-container-1">
                                                <div>
                                                    <div className="field-label">Checking Account Number</div>
                                                    <input id="bank-account-number" className="field-input" placeholder="Checking Account Number" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="button-container">
                                            <div id="processing-fee-message"></div>
                                            <div>
                                                <button className="button-style" id="paybutton">Pay <span id="displayPaymentAmount"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="payment-response-section">
                                        <div id="payment-response"></div>
                                        <div id="payment-reference"></div>
                                    </div>
                                </form>
                            </Grid>
                            <Grid item xs={5} style={{ padding: '10px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <a href="https://www.vacationguard.com/quote/full/TravelClubHigh?agentCode=AA4223"><img src={vgbanner} /></a>
                                    <div style={{ padding: '5px 30px', fontSize: '0.7em' }}>Sample policy and/or flyer includes an example of policy coverages for a product. This is sponsored content from the licensed producers, VacationGuard and Berkshire Hathaway Global Insurance Services, LLC (BHGIS). Coverages, benefits, and availability of product will vary depending on state of residency and information provided in the application, and is limited to United States Residents only. If there is any conflict between the contents of the literature or content in this enrollment and the Policy, the Policy will govern in all cases. Please see your policy for coverages, exclusions, and state specific language.</div>
                                </div>
                            </Grid >
                            <Grid item xs={1}>&nbsp;</Grid>
                        </Grid >

                        <Footer />
                    </div >
                }
            </div>

        )
    }
}

export default OwnerPayments;