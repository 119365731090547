import { Grid } from "@material-ui/core";
import "../css/main.css";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { HeroSecondary } from "../components/HeroSecondary";
import { getCookie } from "typescript-cookie";
import { Owner } from "../interfaces/Owner";
import * as data from '../Data'
import { useEffect, useState } from "react";

export function ServiceRequest() {
    const [owner, setOwner] = useState<Owner>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Owner);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>
            {owner &&
                <div>
                    <Header owner={owner} />
                    <HeroSecondary />

                    <Grid container>
                        <Grid item md={3} xs={1}>&nbsp;</Grid>
                        <Grid item md={6} xs={10} style={{ padding: '40px 0px' }}><div className="SectionTitle">Villa Service Request</div></Grid>
                        <Grid item md={3} xs={1}>&nbsp;</Grid>
                    </Grid>

                    <Grid container>
                        <Grid item md={3} xs={1}>&nbsp;</Grid>
                        <Grid item md={6} xs={10}><div>Intro text to be provided... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div></Grid>
                        <Grid item md={3} xs={1}>&nbsp;</Grid>
                    </Grid>

                    <Grid container>
                        <Grid item md={3} xs={2}>&nbsp;</Grid>
                        <Grid item md={6} xs={8}><div id="confirmation-message" className="confirmationMessage hidden"></div></Grid>
                        <Grid item md={3} xs={2}>&nbsp;</Grid>
                    </Grid>

                    <div id="main-section">

                        <form id="service-request">
                            <Grid container>

                                <Grid item md={3} xs={2}>&nbsp;</Grid>
                                <Grid item md={3} xs={4}>
                                    <div style={{ marginRight: '30px' }}>
                                        <div className="FormLabel">First Name</div>
                                        <input name="firstName" className="FormInput" defaultValue={owner.Name.substring(owner.Name.indexOf('/') + 1)} />
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={4}>
                                    <div style={{ marginRight: '30px' }}>
                                        <div className="FormLabel">Last Name</div>
                                        <input name="lastName" className="FormInput" defaultValue={owner.Name.substring(0, owner.Name.indexOf('/'))} />
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={2}>&nbsp;</Grid>

                                <Grid item md={3} xs={2}>&nbsp;</Grid>
                                <Grid item md={3} xs={4}>
                                    <div style={{ marginRight: '30px' }}>
                                        <div className="FormLabel">Phone Number</div>
                                        <input name="phoneNumber" className="FormInput" defaultValue={owner.Phone1} />
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={4}>
                                    <div style={{ marginRight: '30px' }}>
                                        <div className="FormLabel">Email</div>
                                        <input name="email" className="FormInput" defaultValue={owner.EMailAddress} />
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={2}>&nbsp;</Grid>

                                <Grid item md={3} xs={2}>&nbsp;</Grid>
                                <Grid item md={3} xs={4}>
                                    <div style={{ marginRight: '30px' }}>
                                        <div className="FormLabel">Unit Number</div>
                                        <input name="unitNumber" className="FormInput" />
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={4}>
                                    <div style={{ marginRight: '30px' }}>
                                        <div className="FormLabel">Week Number</div>
                                        <input name="weekNumber" className="FormInput" />
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={2}>&nbsp;</Grid>

                                <Grid item md={3} xs={2}>&nbsp;</Grid>
                                <Grid item md={6} xs={8}>
                                    <div className="FormLabel">How can we help?</div>
                                    <textarea name="requestMessage" className="FormInputTextArea" />
                                </Grid>
                                <Grid item md={3} xs={2}>&nbsp;</Grid>
                            </Grid>
                        </form>

                        <Grid item xs={12}>
                            <div style={{ fontSize: '1.1em', padding: '15px', width: '100%', textAlign: 'center' }} >
                                <button onClick={() => sendMessage(owner)} style={{ margin: '0px 10px', padding: '10px 15px', backgroundColor: '#22a845', border: '0px solid', borderRadius: '5px', color: 'white' }} >Submit Service Request</button>
                            </div>ex
                        </Grid>
                    </div>

                    <Footer />
                </div>

            }
        </div>
    )
}

function sendMessage(owner: Owner | undefined) {
    if (owner) {
        const form = new FormData(document.getElementById('service-request') as HTMLFormElement);
        const formData = Object.fromEntries(form.entries());
        const formDataWithOwnerData = { formFields: formData, ownerData: owner };
        console.log(formDataWithOwnerData);
        const mainSection = document.getElementById('main-section') as HTMLElement;
        mainSection.classList.add('hidden');
        const confirmationMessage = document.getElementById('confirmation-message') as HTMLElement;
        confirmationMessage.innerHTML = "Thank you for submitting your request. We will be in touch shortly.";
        confirmationMessage.classList.remove('hidden');
    }
}
