import { Grid } from '@material-ui/core'
import rmclogo from '../images/rmc-logo.png'

export function Footer() {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container style={{ color: 'white', minHeight: '35vh' }} >
          <Grid item xs={8} style={{ backgroundColor: '#031d42', paddingTop: '40px', paddingLeft: '120px' }} >
            <div className="quick-links">
              <div className="QuickLinksTitle">Quick Links</div>
              <div className="QuickLinks">Contact Us</div>
              <div className="QuickLinks">Bylaws</div>
              <div className="QuickLinks">Timeshare Calendar</div>
              <div className="QuickLinks">Rental Program</div>
            </div>
          </Grid>
  
          <Grid item xs={4} style={{ backgroundColor: '#031d42', paddingTop: '40px', paddingRight: '20px' }} >
            <div className="footer-right">
              <div><img src={rmclogo} /></div>
              <div className="FooterCompanyName">RMC Resort Management</div>
              <div className="FooterCompanyAddress">20 Executive Park Road, Suite 200</div>
              <div className="FooterCompanyAddress">Hilton Head Island, South Carolina 29928</div>
              <div className="FooterCompanyAddress">Phone: (843) 842-6556 ext. 11100</div>
              <div className="FooterCompanyAddress">On-site Office: (843) 842-4649</div>
            </div>
          </Grid>
  
          <Grid item xs={12} style={{ backgroundColor: '#031d42', textAlign: 'center', fontSize: '.7em', padding: '40px' }}>
            &copy; RMC Resort Management 2022
          </Grid>
        </Grid>
      </Grid>  
    )
}